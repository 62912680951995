import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import Connected from "src/ui/icons/pair-connected.svg"
import Disconnected from "src/ui/icons/pair-disconnected.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { SVGProps } from "src/utils/tsUtil"

export function PairConnection({
  offline = false,
  ...props
}: SVGProps & {
  offline: boolean
}) {
  const { t } = useTranslate()

  const connection = offline
    ? { title: t(langKeys.offline), svg: Disconnected }
    : { title: t(langKeys.sensor_status_online), svg: Connected }

  const PairIcon = connection.svg

  return (
    <MTooltip title={connection.title}>
      <div>
        <PairIcon height={24} width={24} {...props} />
      </div>
    </MTooltip>
  )
}
