import { isWaterLeakDetectionDevice } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { SVGProps } from "src/utils/tsUtil"

import { CellularStatus } from "./CellularStatus"
import { EthernetStatus } from "./EthernetIcon"
import { MeshStatus } from "./MeshStatus"
import { PairConnection } from "./PairConnection"
import { WifiStatus } from "./WifiStatus"

export function NetworkStatus({
  device,
  ...props
}: {
  device?: TDevice
} & SVGProps) {
  switch (device?.network_interface) {
    case "wifi":
      return isWaterLeakDetectionDevice(device) ? (
        <PairConnection offline={device.offline} {...props} />
      ) : (
        <WifiStatus
          quality={device.wlan?.signal_quality}
          offline={device.offline}
          {...props}
        />
      )
    case "cellular":
      return (
        <CellularStatus
          quality={device.cellular?.signal_quality}
          offline={device.offline}
          {...props}
        />
      )
    case "ethernet":
      return <EthernetStatus offline={device.offline} {...props} />

    case "burstmesh":
      // FW-985: We don't have a good signal quality measurement yet,
      // for now just show "good" to not alarm/confuse the user.
      return <MeshStatus quality={3} offline={false} {...props} />

    default:
      // If device is undefined or network interface not handled
      return <WifiStatus quality={0} offline={false} {...props} />
  }
}
